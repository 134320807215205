@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;900&family=Volkhov:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: "Volkhov", serif;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header {
  background-color: #fffaf2;
}

.services{
  background-color: #fffaf2;
  
} 
.cardPseudo {
  position: relative;
}

.cardPseudo::after {
  content: "";
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  width: 150px;
  height: 150px;
  border-radius: 1.5rem 0 1rem 0;
  z-index: -1;
  transition: 300ms cubic-bezier(1, 0, 0, 1);
}

.cardPseudo:hover::after {
  bottom: -1.5rem;
  left: -1.5rem;
}
.allServices{
  
  background: rgb(21, 47, 55);
background: linear-gradient(107deg, rgba(21,47,55,1) 0%, rgb(28, 61, 71) 41%);
}
.service-card{
  background-color:#e0af66;
}

